<template>
    <div>
        <div class="endBack2 active">
            <div class="txttitle">
                <span class="endtext">国家统一法律职业资格考试计算机化考试模拟答题演示</span>
            </div>
            <div class="buthome">
                <input type="button" value="关闭窗口" class="inphome" @click="handleClose">
                <!--<input type="button" value="查看结果" class="inphome look" @click="dialogVisible = true">-->
            </div>
        </div>
        <el-dialog
            title="服务号二维码"
            :visible.sync="dialogVisible"
            width="30%">
            <div style="text-align: center">
                <el-image
                    style="width: 200px; height: 200px"
                    :src="require('@/assets/img/qrcode.png')"
                    fit="fill"></el-image>
                <h3>扫码关注，批改完成即可收到通知</h3>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "paperEnd",
    data() {
        return {
            dialogVisible: false
        }
    },
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#2d6dae')
    },
    methods: {
        handleClose() {
            this.$router.push({name: 'index'})
        }
    }
}
</script>

<style scoped>
.buthome {
    position: absolute;
    top: 590px;
    width: 100%;
    text-align: center;
}

.look {
    margin-left: 25px;
}

.inphome {
    width: 90px;
    background: url(../../../assets/img/submit.jpg) repeat-x;
    border: 1px solid #c60;
    color: #000;
    font-size: 13px;
    cursor: pointer;
    border-radius: 0;
    padding: 6px;
}

.endtext {
    position: relative;
    color: #255dad;
    font-size: 32px;
    font-weight: bolder;
}

.txttitle {
    position: absolute;
    top: 190px;
    width: 100%;
    text-align: center;
}

.endBack2 {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1024px;
    height: 768px;
    transform: translate3d(-50%, -50%, 0);
    -webkit-transform: translate3d(-50%, -50%, 0);
    -ms-transform: translateX(-50%) translateY(-50%);
    overflow: hidden;
}

.endBack2.active {
    background: url(../../../assets/img/end2.7ecd182.jpg) no-repeat;
}
</style>